<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="maincard">
				<Toast/>

                <!-- Táblázat -->

				<DataTable ref="dt" :value="areas" v-model:expandedRows="expandedRows" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            sortField="areaname" :sortOrder="1" :loading="loading" @filter="onFilter($event)" @row-click="expandRow"
                            :lazy="true" :totalRecords="totalRecords" @page="onPage($event)" @sort="onSort($event)"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,100]"
							currentPageReportTemplate="{first} - {last} / {totalRecords}" class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows ">
					<template #header>
						<div class="table-header">
                            <div>
                                <Button label="Új" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
                                <h5 class="p-m-0 p-d-inline-block">Karbantartás: Kistérségek</h5>
                            </div>
							<span class="p-input-icon-left">
                                <i :class="(loadingSearch) ? ['pi', 'pi-spin', 'pi-spinner'] : ['pi', 'pi-search']"/>
                                <InputText v-model="searchGlobal" placeholder="Keresés..." />
                            </span>
						</div>
					</template>

					<Column :expander="true" headerStyle="width: 3rem" />
					<Column field="areaname" header="Megnevezés" sortable></Column>
					<Column field="countyname" header="Megye" sortable></Column>
					<Column field="users_csv" header="Beteglátogató(k)" sortable></Column>
					<Column field="subareas_number" header="Települések száma"></Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editArea(slotProps.data.id)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteArea(slotProps.data)" />
						</template>
					</Column>
					<template #expansion="slotProps">
                        <Card>
                        <template #content>
                            <div class="p-grid">
                                <div class="p-col" style="margin-left: 2rem">
                                    <p class="p-d-inline-block" style="margin-right: 1rem">Települések:</p>
                                    <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.subareas_csv"></p>
                                    <br/>
                                    <p class="p-d-inline-block" style="margin-right: 1rem">Megjegyzés:</p>
                                    <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.note"></p>
                                </div>
                                <!--div class="p-col">Város: {{ slotProps.data.package }}</div-->
                            </div>
                        </template>                            
                        </Card>
                    </template>
                </DataTable>


                <!-- Szerkesztő ablak -->

				<Dialog id="dialog" @show="dialogShow" v-model:visible="areaDialog" :style="{width: '450px', 'margin-top': '16px' }" 
                    header="Kistérség" :modal="true" class="p-fluid"
                    @keydown.enter.ctrl="saveArea()" @keyup.esc="hideDialog()">
					<span class="p-field p-float-label">
						<InputText id="areaname" ref="firstEditField" v-model.trim="v$.area.areaname.$model"
                            required="true" :class="{'p-invalid': v$.area.areaname.$invalid}" autocomplete="new-hosp-areaname"
                        />
						<label for="fullname">Megnevezés</label>
						<small class="p-error" v-if="v$.area.areaname.$error">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
                        <AutoComplete id="selected_county" v-model="v$.area.selected_county.$model" :suggestions="filteredCounties" @complete="searchCounty($event)" field="name"
                            :minLength="2" :class="{'p-invalid': v$.area.selected_county.$invalid}" autocomplete="new-hosp-countyid" placeholder="Keresés 2 karaktertől..."
                            forceSelection required="true"
                        />
						<label for="selected_county" style="top: 1px; font-size: 12px;">Megye</label>
						<small class="p-error" v-if="v$.area.selected_county.$error">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
                        <AutoComplete id="selected_user1" v-model="area.selected_user1" :suggestions="filteredUsers" @complete="searchUser($event)" field="name"
                            :minLength="2" autocomplete="new-hosp-countyid" placeholder="Keresés 2 karaktertől..."
                            forceSelection
                        />
						<label for="selected_user1" style="top: 1px; font-size: 12px;">Beteglátogató 1</label>
					</span>
					<span class="p-field p-float-label">
                        <AutoComplete id="selected_user2" v-model="area.selected_user2" :suggestions="filteredUsers" @complete="searchUser($event)" field="name"
                            :minLength="2" autocomplete="new-hosp-countyid" placeholder="Keresés 2 karaktertől..."
                            forceSelection
                        />
						<label for="selected_user2" style="top: 1px; font-size: 12px;">Beteglátogató 2</label>
					</span>
					<span class="p-field p-float-label">
						<Textarea id="description" v-model="area.note" rows="3" cols="20" style="resize: vertical"
                        />
						<label for="description">Megjegyzés</label>
					</span>

					<template #footer>
                        <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            v-tooltip.bottom="'ESC'" tabindex="-1"
                            @click="hideDialog"/>
						<Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                            v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                            @click="saveArea"/>
					</template>
				</Dialog>


                <!-- Törlés kérdés felugró -->

				<Dialog v-model:visible="deleteAreaDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="area">Biztosan töröljük a kistérséget: <b>{{area.areaname}}</b> ?</span>
					</div>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="deleteAreaDialog = false"/>
						<Button label="Törlés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-danger" 
                            @click="deleteArea"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
//import { mapGetters, mapActions } from 'vuex'
import AreaService from '@/service/backend/area.service'
import CountyService from '@/service/backend/county.service'
import UsersService from '@/service/backend/users.service'
import useVuelidate from '@vuelidate/core'
import { required, errorMessages } from  '@/texts/errorMessages'

export default {
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
	data() {
		return {
			areas: null,
			areaDialog: false,
			deleteAreaDialog: false,
			deleteAreasDialog: false,
			area: {},
            counties: null,
            filteredCounties: null,
            users: null,
            filteredUsers: null,
			expandedRows: [],
			filters: {},
            submitted: false,
            searchGlobal: "",
            totalRecords: 0,
            lazyParams: {},
            loading: true,
            loadingSearch: false,
            searchInterval: null,
            errorMessages,

		}
	},
    validations: {
        area: {
            areaname: { required },
            selected_county: { required }
        }
    },
    areaService: null,
    countyService: null,
    usersService: null,
	created() {
		this.areaService = new AreaService()
		this.countyService = new CountyService()
		this.usersService = new UsersService()
    },
    beforeMount() {
        //this.loadCodeStore('areaClass') // Abban az esetben, ha API-s a codeStore - de nem kell ezt tudnia, jöhetne
    },
	mounted() {
        this.lazyParams = {
            first: 0,
            page: 1,
            rows: this.$refs.dt.rows,
            sortField: this.$refs.dt.sortField,
            sortOrder: 1,
            filters: null
        };

        this.loadLazyData();
	},
	methods: {
/*        ...mapActions({
            getCodeStore: 'codeStore/loadCodeStore'
        }), */
        loadLazyData() {
            this.loading = true;

            delete this.lazyParams.originalEvent
            delete this.lazyParams.pageCount
            delete this.lazyParams.filterMatchModes
            this.lazyParams.filters = this.filters.global
            if(this.lazyParams.sortField == 'users_csv') this.lazyParams.sortField = 'username1'

            this.areaService.getAreas(this.lazyParams)
            .then( result => {
                result.data.forEach(e => { 
                    e.subareas_number = e.subareas.length 
                    e.subareas_csv = e.subareas.map( x => x.postcode + ' ' + x.subareaname).join(', ')
                    e.users_csv = e.username1 
                    e.users_csv+= (e.username2!="") ? ', '+e.username2 : ""
                })
                this.areas = result.data
                this.totalRecords = result.totalRecords
                this.loading = false
            })
        },
        onPage(event) {
            this.lazyParams = event
            this.lazyParams.page++
            this.loadLazyData()
        },
        onSort(event) {
            this.onPage(event)
        },
        onFilter() {
            this.loading = true
            this.lazyParams.page = 1
            this.loadLazyData()
            this.loadingSearch = false
        },
        expandRow(e) {
            if(this.expandedRows.length === 0 || this.expandedRows[0].id !== e.data.id ){
                this.expandedRows = this.areas.filter(p => p.id == e.data.id);
            }else{
                this.expandedRows = []
            }
        },
        openDialog(){
            this.submitted = false
            this.areaDialog = true
            this.$nextTick(() => { this.v$.$reset() })
        },
		openNew() {
            this.area = { selected_user1: {id: '', name: '' }, selected_user2: {id: '', name: '' } }
            this.openDialog()
		},
        dialogShow() {
            this.$refs.firstEditField.$el.focus()
        },
		hideDialog() {
			this.areaDialog = false
            this.submitted = false
		},
        searchCounty(event) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.countyService.getCountiesForSelect({filters: event.query })
                .then( result => {
                    this.filteredCounties = result
                })
            }, 600)

        },
        searchUser(event) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.usersService.getUsersForSelect({filters: event.query })
                .then( result => {
                    this.filteredUsers = result
                })
            }, 600)

        },
		async saveArea() {

            this.submitted = true;
            this.v$.area.$touch()

            if (!this.v$.$invalid) {

                    try{
                        this.area.countyid = this.area.selected_county.id
                        this.area.countyname = this.area.selected_county.name
                        this.area.userid1 = this.area.selected_user1==null ? '' : this.area.selected_user1.id
                        this.area.username1 = this.area.selected_user1==null ? '' : this.area.selected_user1.name
                        this.area.userid2 = this.area.selected_user2==null||this.area.selected_user2.id==null ? '' : this.area.selected_user2.id
                        this.area.username2 = this.area.selected_user2==null||this.area.selected_user2.id==null ? '' : this.area.selected_user2.name
                        this.area.users_csv = this.area.username1 
                        this.area.users_csv+= (this.area.username2!="") ? ', '+this.area.username2 : ""

                        if (this.area.id) {
                            this.area.id = await this.areaService.updateArea(this.area)
                            this.areas[this.findIndexById(this.area.id)] = this.area
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})
                        }
                        else {
                            this.area.id = await this.areaService.newArea(this.area)
                            if(this.areas.length == this.$refs.dt.rows){
                                this.areas.pop()
                            }
                            this.areas.unshift(this.area)
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                        }
                    }catch(e){
                        console.log(e)
                        // TODO server oldali hiba kiírása?
                        this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
                    }finally{
                        this.submitted = false
                    }

                    this.hideDialog()
            
            }

            this.submitted = false

		},
		editArea(id) {
            this.area = JSON.parse(JSON.stringify(this.areas.find( d => d.id === id)));
            this.area.postcode = parseInt(this.area.postcode)

            let val = this.area.countyid ? {id: this.area.countyid, name: this.area.countyname} : ""
            this.area['selected_county'] = val

            val = this.area.userid1 ? {id: this.area.userid1, name: this.area.username1} : ""
            this.area['selected_user1'] = val

            val = this.area.userid2 ? {id: this.area.userid2, name: this.area.username2} : ""
            this.area['selected_user2'] = val

            this.openDialog()
		},
		confirmDeleteArea(area) {
			this.area = area
			this.deleteAreaDialog = true
		},
		async deleteArea() {
            this.submitted = true
            try{
                await this.areaService.deleteArea(this.area.id)
                this.areas = this.areas.filter(val => val.id !== this.area.id)
                this.deleteAreaDialog = false
                this.area = {}
                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Törölve!', life: 3000})
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }
            this.submitted = false
		},
		findIndexById(id) {
			let index = -1
			for (let i = 0; i < this.areas.length; i++) {
				if (this.areas[i].id === id) {
					index = i
					break
				}
			}
			return index;
		},
		exportCSV() {
			this.$refs.dt.exportCSV()
		}
    }, 
    watch: {
        searchGlobal(val) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.loadingSearch = true
                setTimeout(() => { this.filters['global'] = val; this.onFilter(); }, 20)
            }, 600)
        }
    },
    computed: {
/*        ...mapGetters({
            codesAreaClass: 'codeStore/areaClass'
        })  */
    }
}
</script>
<style>
.p-datatable-tbody {
    cursor: pointer;
}
.p-datatable-row-expansion {
    cursor: auto;
}
.p-card .p-card-content {
    padding: 0 0 0.2rem 0;
}
</style>

<style scoped lang="postcss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.area-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .area-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.area-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

#maincard {
    padding-top: 0px;
}

h5 {
    padding-left: 1.6rem;
    padding-bottom: 0.6rem;
}


@media screen and (max-width: 960px) {
	::v-deep(.p-datatable)  {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}

	::v-deep(.p-toolbar) {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
    }
    
}
</style>
