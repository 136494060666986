import ApiService from './api.service'

export default class CountyService {

	/*async getCounties(_params) {
        const response = await ApiService.get('api/areas', _params)
        return response.data
    }*/

	async getCountiesForSelect(_params) {
        const response = await ApiService.get('api/counties/select', _params)
        return response.data.data
    }

}
